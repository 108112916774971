<template>
  <div>
    <div class="zhfwtop">
      <div class="indeximgtopcontent">
         <div class="indexlogo"></div>
      <div class="indexlist">
          <ul class="indexlistul">
            <li  @click="go('/home')"><span>首页</span></li>
            <li  class="indexli2">
              <span >产品解决方案</span>
                <ul class="menu">
                  <li  @click="go('/cpjjfa/zhfw')">智慧法务</li>
                  <span  @click="go('/cpjjfa/zhcg')">智慧采购</span>
                  <li  @click="go('/cpjjfa/zhrz')">智慧人资</li>
                  <li @click="go('/cpjjfa/zhsj')">智慧审计</li>
                  <li  @click="go('/cpjjfa/bgxt')">办公协同</li>
                  <li @click="go('/cpjjfa/dsj')">大数据分析-BI技术平台</li>
                </ul>
            </li>
            <li @click="go('/jszc')"><span>技术支持</span></li>
            <li @click="go('/yhal')"><span>用户案例</span></li>
            <li @click="go('/gywm')"><span class="active2">关于我们</span></li>
          </ul>
      </div>
      </div>

      <div class="zhfwbutton"></div>
    </div>
    <!-- morecontent --> 
    <div class="morecontent">
      <div class="morecontenttitle">【赢战2022】—中科星图瑞云科技有限公司2021年终工作总结暨年会盛典圆满落幕！</div>
      <div class="morecontent1">
        <p>同唱和谐新乐章，共绘未来好宏图。</p>
        <p>2022年2月19日，我公司的年会如期而至，全体员工欢聚一堂，细数2021年一年中大家风雨同舟，奋进拼搏的点点滴滴，共同展望公司未来发展的蓬勃蓝图，在欢快、喜悦中度过了一个难忘的夜晚。</p>
      </div>
      <div class="morecontent1png"> <img src="../../.././assets/morecontent1png.png" alt=""> </div>
      <div class="morecontent2">
        <p>年会现场，公司高管首先向全体员工致以新年贺词，表达了对中科星图瑞云员工的深切关怀以及对公司发展的期许，领导讲话饱含深情，催人奋进，相信22年公司必将再上一个新的台阶。</p>
        <p>年会上半部份主要是精彩的节目表演和激动人心的抽奖环节，现场节目精彩纷呈，歌曲、小品、互动游戏等令现场高潮迭起，其乐融融。</p>
      </div>
      <div class="morecontent2png"><img src="../../.././assets/morecontent2png.png" alt=""> </div>
      <div class="morecontent3">
        <p>下半部份是晚宴时间，以奖为主，含优秀团队颁奖、优秀员工颁奖、更有万分激动的大奖出现。中科星图瑞云一家人共同感受温馨氛围，共同期待公司在新的一年里向着更高的目标展翅翱翔。晚宴上全体员工为新的一年举杯同庆，共同祝愿聚能的明天会更好。整个年会在和谐、温馨、激情、欢乐的气氛中圆满落下帷幕，展现了聚能员工充满活力、积极向上、团结进取的精神面貌。</p>
        <p>回首2021，我们齐心协力，努力拼搏，共同收获:展望2022，我们目标一致，信心满满，共同期待聚能的明天会更加辉煌。</p>
      </div>
      <div class="morecontent3png"><img src="../../.././assets/morecontent3png.png" alt=""> </div>
    </div>
    <tabar-bottom></tabar-bottom>
  </div>
</template>

<script>
import TabarBottom from '../../../components/TabarBottom.vue'
  export default {
  components: { TabarBottom },
    data(){
      return{

      }
    },
    methods:{
      go(path){
        this.$router.push({
          path:path
        })
      }
    }
  }
</script>

<style lang="scss" scoped>
.indeximgtopcontent{
  width: 1223px;
  height: 46px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  // border: 1px solid red;
}
.morecontent{
  width: 1200px;
  height: 2609px;
  margin: 0 auto;
  .morecontenttitle{
    margin: 88px auto 80px auto;
    width: 813px;
    height: 28px;
    font-size: 28px;
    font-family: Source Han Sans CN-Bold, Source Han Sans CN;
    font-weight: bold;
    color: #282A2E;
  }
  .morecontent1{
    width: 1200px;
    height: 80px;
    font-size: 18px;
    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
    font-weight: 400;
    color: #282A2E;
    line-height: 28px;
    p{
      text-indent: 2rem;
    }
  }
  .morecontent1png{
    width: 1200px;
    height: 640px;
    margin-top: 40px;
  }
  .morecontent2{
    width: 1200px;
height: 84px;
font-size: 18px;
font-family: Source Han Sans CN-Regular, Source Han Sans CN;
font-weight: 400;
color: #282A2E;
margin-top: 40px;
margin-bottom: 40px;
line-height: 28px;
p{
  text-indent: 2rem;
}
  }
  .morecontent2png{
    width: 1200px;
    height: 640px;
  }
  .morecontent3{
    width: 1200px;
    height: 116px;
    line-height: 28px;
    font-size: 18px;
    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
    font-weight: 400;
    color: #282A2E;
    margin-top: 40px;
    margin-bottom: 40px;
    p{
      text-indent: 2rem;
    }
  }
  .morecontent3png{
    width: 1200px;
    height: 640px;
  }
}
//
.active{
  display: inline-block;
  color: #FFA000;
  line-height: 30px;
}
.active2{
  display: inline-block;
  color: #FFA000;
  line-height: 30px;
  border-bottom: 1px solid #FFA000;
}
.zhfwtop{
  // width: 1903px;
  height: 500px;
  background: url('../../.././assets/gywmindexback.png') no-repeat center 0;
  margin: 0 auto;
  padding-top: 34px;
  .indexlogo{
  width: 220px;
  height: 52px;
  background: url('../../.././assets/logo.png') no-repeat center center;
}
.zhfwbutton{
  float: left;
  width: 373px;
  height: 175px;
  margin-left: 774px;
  margin-top: 90px;
}
.zhfwindc{
  text-align: center;
  float: left;
  width: 1100px;
  height: 51px;
  font-size: 16px;
  margin-top: 20px;
  margin-left: 432px;
  font-family: Source Han Sans CN-Regular, Source Han Sans CN;
  font-weight: 400;
  color: #FFFFFF;
}
.indexlist{
  width: 632px;
  font-size: 14px;
  font-family: Source Han Sans CN-Medium, Source Han Sans CN;
  font-weight: 500;
  color: #FFFFFF;
  .indexlistul{
    display: flex;
    text-align: center;
    li{
      cursor: pointer;
      width: 160px;
      line-height: 45px;
      span:hover{
        cursor: pointer;
        text-align: center;
        color: #FFA000;
      }
    }

  }
}
//
.menu{
  position: absolute;
  display: none;
  top: 45px;
  background: #fff;
  color: #282A2E;
  padding-left: 20px;
  text-align: left;
  left: 16px;
}
.menu li:hover{
  color: #FFA000;
}
.indexli2{
  position: relative;
}
.indexli2:hover .menu{
  display: block;
}
}
</style>
